<template>
    <div class="competition-wrapper">
        <div class="competition-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/competition' }">竞赛模板管理</el-breadcrumb-item>
                <el-breadcrumb-item>竞赛模板列表</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" @click="addCompetition">创建竞赛模板</el-button>
        </div>
        <el-table :data="competitionList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="tmpl_name" align="center" label="竞赛名称" width="300" :resizable="false"></el-table-column>
            <el-table-column prop="tmpl_name_alias" align="center" label="模板名称" width="300" :resizable="false"></el-table-column>
            <el-table-column prop="module" align="center" label="竞赛模块" :resizable="false">
                <template slot-scope="scope">
                    <span v-for="item in scope.row.expand">
                        {{(item.tmpl_module_type === 1) ? '装修' : ((item.tmpl_module_type === 2) ? '运营' : ((item.tmpl_module_type === 3) ? '客服' : (item.tmpl_module_type===4)?'路演':(item.tmpl_module_type===6)?'数据分析':(item.tmpl_module_type===7)?'新客服':'理论'))}}（{{item.tmpl_module_percent}}%）
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200" :resizable="false">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" type="primary" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="deleteBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="competitionPages.currentPageNum"
                       :page-size="competitionPages.eachPageNum"
                       :total="competitionPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="competitionCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "Competition",
        data() {
            return {
                //竞赛列表
                competitionList: [],
                //分页
                competitionPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getCompetitionList();
        },
        methods: {
            //获取竞赛列表
            getCompetitionList() {
                let param = {
                    paging: '1',
                    page: this.competitionPages.currentPageNum,
                    pageSize: this.competitionPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.templateList, param, (res) => {
                    if (res.code === 200) {
                        this.competitionList = res.data.data;
                        this.competitionPages.total = res.data.total;
                        if (this.competitionPages.total !== 0 && this.competitionList.length === 0) {
                            this.competitionPages.currentPageNum--;
                            this.getCompetitionList();
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //创建竞赛
            addCompetition() {
                this.$router.push('/admin/createcompetition')
            },
            //分页
            competitionCurrentChange(val) {
                this.competitionPages.currentPageNum = val;
                this.getCompetitionList();
            },
            //编辑
            editBtn(row) {
                this.$router.push({
                    path: '/admin/createcompetition',
                    query: {
                        id: row.id
                    }
                })
            },
            //删除
            deleteBtn(id) {
                this.$confirm('是否删除该模板，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let formData = new FormData();
                    formData.append('id', id);
                    this.$http.axiosPost(this.$api.templateDelete, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getCompetitionList();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .competition-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .competition-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .el-link + .el-link {
            margin-left: 10px;
        }
    }
</style>